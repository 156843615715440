.grouped-avatar-container {
  border-radius: 50%;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%);
  text-align: center;
  overflow: hidden;
  margin-left: -14px;
}

.grouped-avatars {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.avatar-text {
  font-family: 'Satoshi-Bold' !important;
  font-weight: bold !important;
  line-height: 0 !important;
  font-size: 16px;
}
