@media (max-width: 480px) {
  .popup-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .popup-title {
    padding-left: 0 !important;
  }
}

.popup-title {
  text-align: start !important;
}

.popup-title h3 {
  font-family: 'Satoshi-Bold';
  color: #000000 !important;
  margin: 0 !important;
  font-size: 22px;
}

.popup-title p {
  color: #000000 !important;
  margin-bottom: 0 !important;
  font-size: 14px !important;
}
