/* Loading dots styles */
@-webkit-keyframes opacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes opacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.btn-loading {
  text-align: center;
}

.btn-loading span:not(:last-child) {
  margin-right: 5px;
}

.btn-loading span {
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;

  animation-name: opacity;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  -moz-animation-name: opacity;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
}

.btn-loading span:nth-child(2) {
  animation-delay: 100ms;
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
}

.btn-loading span:nth-child(3) {
  animation-delay: 300ms;
  -webkit-animation-delay: 300ms;
  -moz-animation-delay: 300ms;
}
