.btn-hamburger {
  position: relative;
  width: 40px;
  height: 25px;
  cursor: pointer;
}

.btn-hamburger .btn-hamburger__cheeckbox {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  z-index: 2;
  -webkit-touch-callout: none;
  position: absolute;
  opacity: 0;
}

.btn-hamburger div {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 22px;
  height: 12px;
}

.btn-hamburger span {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #212121;
  border-radius: 1px;
  transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}

.btn-hamburger .ham-toggled span {
  background-color: #fbfbfb !important;
}

.btn-hamburger span:first-of-type {
  top: -2px;
}

.btn-hamburger span:nth-of-type(2) {
  top: 5px;
}

.btn-hamburger span:last-of-type {
  bottom: -2px;
}

.btn-hamburger.active span:first-of-type,
.btn-hamburger .btn-hamburger__cheeckbox:checked + div span:first-of-type {
  transform: rotate(45deg);
  top: 5px;
}

.btn-hamburger.active span:nth-of-type(2),
.btn-hamburger .btn-hamburger__cheeckbox:checked + div span:nth-of-type(2) {
  opacity: 0;
}

.btn-hamburger.active span:last-of-type,
.btn-hamburger .btn-hamburger__cheeckbox:checked + div span:last-of-type {
  transform: rotate(-45deg);
  bottom: 5px;
}

.btn-hamburger.active:hover span:first-of-type,
.btn-hamburger.active:hover span:last-of-type,
.btn-hamburger:hover .btn-hamburger__cheeckbox:checked + div span:first-of-type,
.btn-hamburger:hover .btn-hamburger__cheeckbox:checked + div span:last-of-type {
  width: 22px;
}

@media (min-width: 1024px) {
  .btn-hamburger:hover span:first-of-type {
    width: 26px;
  }

  .btn-hamburger:hover span:last-of-type {
    width: 12px;
  }
}
